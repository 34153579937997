import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

export default ({size}) => (
  <Root to="inscription" className={`cta cta-signup v-${size || 'default'}`}>Inscrivez-vous gratuitement</Root>
)

const Root = styled(Link)`
  display: inline-block;
   background: #1C4397;
    &:hover {
      background: #07f;
      color: #FFF !important;
    }
    color: #FFF;
    font-weight: 500;
    border-radius: 4px;
    padding: 22px 20px;
    font-size: 1.35em;
    letter-spacing: 0.02em;  
    
    &.v-md {
      padding: 20px 15px;
      font-size: 1em;
    }
`

