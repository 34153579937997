import React from 'react'
import Helmet from 'react-helmet'

export default ({ site, className, page }) => {
  let ld = {
    '@context': 'http://schema.org',
    '@type': site.type,
    'url': site.url,
  }
  if (site.contact) {
    ld['contactPoint'] = [
      {
        '@type': 'ContactPoint',
        'telephone': site.contact.telephone,
        'contactType': 'customer service',
        'availableLanguage': site.contact.availableLanguage,
      },
    ]
  }
  if (site.address) {
    ld['address'] = {
      '@type': 'PostalAddress',
      'streetAddress': site.address.street,
      'addressLocality': site.address.locality,
      'addressRegion': site.address.region,
      'postalCode': site.address.postal,
      'addressCountry': site.address.country,
      'geo': {
        '@type': 'GeoCoordinates',
        'latitude': site.address.geo[0],
        'longitude': site.address.geo[1],
      },
    }
  }

  if (site.openingHoursSpecification) {
    ld['openingHoursSpecification'] = [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: site.openingHours.weekdays[0],
        closes: site.openingHours.weekdays[1],
      },
    ]
  }

  return (
    <Helmet
      title={site.seoTitle}
      meta={[
        { name: 'description', content: site.name },
        { name: 'keywords', content: (site.keywords || []).join(',') },
      ]}>
      <body className={'navbar-fixed-top page-' + ( page || 'general') + ' ' + className}/>
      <meta property="og:title" content={site.seoTitle}/>
      <meta property="og:site_name" content={site.name}/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={site.url}/>
      <script type="application/ld+json">
        {` ${ JSON.stringify(ld) }`}
      </script>
    </Helmet>
  )
}
