// noinspection SpellCheckingInspection
export default {
  site: {
    domain: "vitepay.com",
    url: 'https://vitepay.com',
    type: 'LocalBusiness',
    name: 'Vitepay',
    seoTitle: "Vitepay: L'e-commerce démocratisé",
    logo: '/img/logo_dark.svg',
    twitter_card: '/img/social_image.png',
    social_image: '/img/social_image.png',
    logoLight: '/img/logo_white.svg',
    logoDark: '/img/logo_dark.svg',
    logoColored: '/img/logo_colored.svg',
    description: "Vitepay: L'e-commerce démocratisé",
    missionQuote: `TODO`,
    keywords: ['vitepay', 'sillaps', 'logineo', 'shop', 'commerce', 'saniweb', 'orange'],
    cdn: '/public',
  }
}
