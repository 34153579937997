import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import content from '../resources/content'
import Head from '../components/Head'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// ----------------------------------------------------------------------------

import '../resources/css/index.scss'

const site = content.site

const Layout = ({ page, className, children }) => (
  <Fragment>
    <Head page={page} className={className} site={site}/>
    <Navbar/>
    <div className="content clearfix">
      {children}
    </div>
    <Footer cta={ page !== "register" }/>
  </Fragment>
)


Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout

/*
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0,
          }}
        >
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
*/
