import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import CTASignup from './CTASignup'

const assets = {
  logoOval: require('../resources/img/vp_logo_oval.png'),
}

export default ({ cta }) => (
  <Root>
    <footer>

      <div className="container">

        {cta !== false && (
          <div className="clearfix start-now">
            <p>Démarrez avec Vitepay</p>
            <div className="row">
              <div className="col-8">
                <h2>Intégrez Vitepay à votre business en quelques étapes</h2>
              </div>
              <div className="col-4 text-right">
                <div className="mt-2">
                  <CTASignup size="md"/>
                </div>
              </div>
            </div>
          </div>
        )
        }

        <div className="row inactive">
          <div className="col-3">
            <h4>Pourquoi Vitepay</h4>
            <ul className="v-footer-list list-unstyled">
              <li><Link to="pourquoi">Pourquoi choisir Vitepay</Link></li>
            </ul>
            <ul className="v-footer-list list-unstyled">
              <li><Link to="produits">Vitepay pour les Entrepreneurs</Link></li>
              <li><Link to="produits">Vitepay en Entreprise</Link></li>
              <li><Link to="produits">Vitepay pour l'Education</Link></li>
            </ul>
          </div>

          <div className="col-3">
            <h4>Prix</h4>
            <ul className="v-footer-list list-unstyled">
              <li><Link to="produits">Produits</Link></li>
              <li><Link to="pricing">Combien ça coûte ?</Link></li>
            </ul>

            {/*<h4>Clients</h4>
            <ul className="v-footer-list list-unstyled">
              <li><Link to="support">Relation clients</Link></li>
            </ul>*/}

            <h4>Partenaires</h4>
            <ul className="v-footer-list list-unstyled">
              <li><Link to="/partenaires">En savoir plus sur nos partenaires</Link></li>
            </ul>
          </div>

          <div className="col-3">
            <h4>Développeurs</h4>
            <ul className="v-footer-list list-unstyled">
              <li><a href="https://developers.vitepay.com" target="_blank" rel="noopener noreferrer">Documentation</a></li>
              <li><Link to="#">Etat de nos services</Link></li>
            </ul>

            <h4>Support</h4>
            <ul className="v-footer-list list-unstyled">
              <li><Link to="support">Foire aux questions</Link></li>
              <li><Link to="support#contact">Nous contacter</Link></li>
            </ul>
          </div>

          <div className="col-3">
            <h4>A propos</h4>
            <ul className="v-footer-list list-unstyled">
              <li><Link to="/">Vitepay</Link></li>
              <li><Link to="#">Kit Media</Link></li>
              <li><Link to="politique">Politique de confidentialité</Link></li>
              <li><Link to="conditions">Conditions d'utilisation</Link></li>
            </ul>
          </div>

        </div>
        <p>&nbsp;</p>
        <hr/>
        <p>&nbsp;</p>

        <div className="row">
          <div className="col-2">
            <img src={assets.logoOval} width={60} alt="Vitepay"/>
          </div>
          <div className="col-3">
            <h4>Contact</h4>
            <ul className="v-footer-list list-unstyled active">
              <li><Link to="support">WhatsApp</Link></li>
              <li><Link to="support">Support Email</Link></li>
              <li><Link to="support">Service commercial</Link></li>
            </ul>
          </div>
          <div className="col-4">
            <h4>Adresse</h4>
            <p>
              2572, Bamako, rue 94, porte 50601<br/>
              H2J5+CJ Bamako, Mali
            </p>
          </div>

          <div className="col-3">
            <p className="copyright">
              &copy; {new Date().getFullYear()} Vitepay. Tous droits réservés.
            </p>
          </div>
        </div>

        <p>&nbsp;</p>
      </div>
    </footer>
  </Root>
)

const Root = styled.div`

  padding-top: 80px;
  font-size: 0.95em;

  h4 {
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 15px;
    margin: 10px 0 10px;
  }
  
  p.copyright {
    font-size: 0.9em;
    padding-top: 55px;
  }
  
  .inactive a {
    color: #1c2233;
    &:hover {
      color: #1C4397
    }
  }
  
  .start-now {
    padding: 0 0 80px 0;
    p {
      font-size: 1.2em;
    }
    
  }
  
`
