import React from 'react'
import styled from 'styled-components'
import {logoFull} from '../resources'
import {Link} from 'gatsby'
import NavLink from './NavLink'

export default () => (
  <Root className="navbar navbar-expand-lg">
    <Link className="navbar-brand" to="/">
      <img src={logoFull()} alt="Logo" width={100}/>
    </Link>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <NavLink to="/pourquoi">Pourquoi Vitepay</NavLink>
        </li>
       <li className="nav-item">
          <NavLink to="/produits">Produits</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/partenaires">Partenaires</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/pricing">Combien ça coûte?</NavLink>
        </li>
      </ul>
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link nav-ext" rel="noopener noreferrer" href="https://developers.vitepay.com" target="_blank" title="">Développeurs</a>
        </li>
        <li className="nav-item">
          <NavLink to="/support">Support</NavLink>
        </li>
        <li className="nav-item">
          <a className="nav-link nav-ext" rel="noopener noreferrer" href="https://app.vitepay.com" target="_blank" title="">Connexion</a>
        </li>
        <li className="nav-item">
          <Link className="nav-link nav-focus" to="/inscription">Inscription gratuite</Link>
        </li>
      </ul>
    </div>
  </Root>
)


const Root = styled.nav`
  background: none;
  padding: 35px 60px 25px 60px !important;
  position: relative;
  z-index: 10;
  
  .navbar-brand {
    padding: 0;
    margin-top: -3px;
    margin-right: 40px;
  }
  
  .nav-item .nav-link {
    color: #2B3743;
    font-size: 1.1em;
    padding: 0 0;
    letter-spacing: 0;
    margin: 0 10px;
    font-weight: 500;
    
    &:hover {
      color: #8492a6;
    }
    
    &.nav-focus {
      background: #1C4397;
      &:hover {
        background: #07f;
      }
      font-size: 1em;
      color: #FFF;
      font-weight: 500;
      border-radius: 3px;
      padding: 10px 15px;
      margin-top: -9px;
    }
    
    &.active {
      color: #1C4297;
    }
    
  }
  
`